<template>
  <div>
    <h1>This is page Rules</h1>
  </div>
</template>
<script>
export default {
  name: 'Rules',
}
</script>
